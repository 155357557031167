import JshModule from '@core/scripts/helper/jsh-module';
import {showPictureAtVideoError} from './video-fallback.js';
import '@mux/mux-player';
import getOnDemandVideoWithControls from './video-with-controls.js';

const BREAKPOINT_DESKTOP = 768;

/**
 * only for overridable videos, i.e. focus Teaser where Video can be overridden on desktop
 **/
window.addEventListener('DOMContentLoaded', () => {
    do {
        const {moduleScope} = JshModule('teaser/video-loader-overridable');
        if (moduleScope !== null) {
            const mobilePlaybackId = moduleScope.getAttribute('mobile');
            const overridePlaybackId = moduleScope.getAttribute('override');
            const description = moduleScope.getAttribute('description');
            const longVideo = moduleScope.getAttribute('long-video');

            let usedPlaybackId;

            if (window.innerWidth >= BREAKPOINT_DESKTOP && overridePlaybackId) {
                usedPlaybackId = overridePlaybackId;
            } else {
                usedPlaybackId = mobilePlaybackId;
            }

            let muxVideo;
            if (longVideo) {
                muxVideo = getOnDemandVideoWithControls({
                    playbackId: usedPlaybackId,
                    withoutOverrideVideo: !overridePlaybackId,
                    moduleScope,
                    description,
                });
            } else {
                muxVideo = getMutedLiveAutoplayVideo(usedPlaybackId, moduleScope);
            }

            muxVideo.addEventListener('error', () => {
                showPictureAtVideoError(muxVideo);
            });

            moduleScope.replaceWith(muxVideo);
        }
    } while (document.querySelector('[jsh-module="teaser/video-loader-overridable"]') !== null);
});

function getMutedLiveAutoplayVideo(usedPlaybackId, moduleScope) {
    const liveAutoplayVideo = document.createElement('mux-player');
    liveAutoplayVideo.setAttribute('disable-cookies', 'true');
    liveAutoplayVideo.setAttribute('disable-tracking', 'true');
    liveAutoplayVideo.setAttribute('loop', '');
    liveAutoplayVideo.setAttribute('stream-type', 'live');
    liveAutoplayVideo.setAttribute('default-stream-type', 'live');
    liveAutoplayVideo.setAttribute('autoplay', '');
    liveAutoplayVideo.setAttribute('muted', '');
    liveAutoplayVideo.setAttribute('class', 'content-platform__mux-video');
    liveAutoplayVideo.setAttribute('playback-id', usedPlaybackId);
    return liveAutoplayVideo;
}
